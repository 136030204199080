<template>
  <Dialog style="width: 30%" header="添加企业" v-model:visible="display">

    <div style="width: 90%;margin:20px 5% 20px;">
      <InputNumber style="width: 100%" :useGrouping="false" class=" p-mb-3" v-model="from.phone" placeholder="企业联系人手机号码"></InputNumber>
      <InputText style="width: 100%" class=" p-mb-3" v-model="from.name" placeholder="企业登录名"></InputText>
      <InputText style="width: 100%" class=" p-mb-3" v-model="from.password" placeholder="密码"></InputText>
      <div style="width: 100%;display: flex;justify-content: flex-end">
        <Button class="pi pi-save" @click="submit"> 添加</Button>
      </div>
    </div>

  </Dialog>
  <Toast/>
</template>

<script>

export default {
  name: "addCompanies",
  data() {
    return {
      display: false,
      from: {
        name: null,
        password: null
      }
    }
  },
  methods: {
    init() {
      this.display = true
    },
    submit() {
      let ths = this
      let errMsg;
      if (!(/^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/.test(this.from.phone))) {
        errMsg = '手机号码格式不正确。';
      } else if (this.from.phone == undefined || this.from.name == undefined || this.from.password == undefined) {
        errMsg = '企业联系人手机号码、企业名称、密码均不可为空。';
      }
      if (errMsg != undefined) {
        ths.$toast.add({
          severity: 'warn',
          summary: errMsg,
          life: 3000
        })
        return;
      }

      this.$http.post('/company/addCompanyUser', this.from).then((res) => {
        if (res.status == 200) {
          this.display = false
          ths.$toast.add({
            severity: 'success',
            summary: '添加成功',
            life: 3000
          })
          this.$emit('close', false)
        } else {
          ths.$toast.add({
            severity: 'warn',
            summary: res.data,
            life: 3000
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
