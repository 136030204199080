<template>
  <div class=" card ">
    <Breadcrumb :model="items"/>
    <add-companies v-on:close="getDataList" v-if="addCompanies" ref="addCompanies"></add-companies>
    <div class="p-mt-3">
      <DataTable ref="dt"
                 :auto-layout="true"
                 :value="products">
        <template #header>
          <div style="display: flex">
            <Button label="添加企业" @click="addCompaniesEvent" class="p-mr-2" icon="pi pi-fw pi-plus"/>
            <InputText v-model="name" placeholder="企业名行业"/>
            <Calendar @clear-click="getDataList" onfocus=this.blur() v-model="time" style="width: 22.5%" class="p-ml-3"
                      placeholder="入驻时间"
                      :showButtonBar="true" dateFormat="yy-mm-dd"/>
            <Button @click="getDataList" class="p-ml-2">查询</Button>
          </div>
        </template>
        <Column field="name" header="企业名称" headerStyle="width: 10rem"></Column>
        <Column field="phone" header="联系人/电话" headerStyle="width: 12rem" :sortable="false"></Column>

        <Column field="companiesInformation.creditCode" header="统一信用代码" :sortable="false"></Column>
        <Column field="addTime" header="入驻时间" headerStyle="width: 15rem" :sortable="true">
          <template #body="slotProps">
            {{ new Date(slotProps.data.addTime).toLocaleString() }}
          </template>
        </Column>
        <Column field="companiesInformation.industry" header="行业" :sortable="false"></Column>
        <Column field="operation" header="操作" :exportable="false">
          <template #body="slotProps">
            <Button :class="{'p-button-danger':slotProps.data.memberFlag===1}"
                    class="p-button-sm  p-mr-2" @click="setMember(slotProps.data)">{{slotProps.data.memberFlag===1?'取消正式会员':'设为正式会员'}}</Button>
            <Button class="p-button-sm p-mr-2" @click="goto(slotProps.data)">编辑</Button>
            <!--              <Button class="p-ml-1 p-mt-1">置顶展示</Button>-->
            <Button class="p-button-danger p-button-sm " @click="confirmDeleteProduct(slotProps.data)">移除企业</Button>
          </template>
        </Column>
      </DataTable>
      <Paginator @page="changePage" :rows="page.size" :totalRecords="page.total" :rowsPerPageOptions="[5,10,25]"
                 currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
                 template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
    </div>
    <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="product">你确认要移除 <b>{{ product.companiesName }}</b>?</span>
      </div>
      <template #footer>
        <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
        <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteProductsDialog" :style="{width: '350px'}" header="确认" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="product">你确认要删除所选项吗？</span>
      </div>
      <template #footer>
        <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
        <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts"/>
      </template>
    </Dialog>
  </div>
  <Toast/>
</template>

<script>
import addCompanies from "@/views/platform/memberManagement/addCompanies";

export default {
  name: "",
  components: {addCompanies},
  data() {
    return {
      name: null,
      time: null,
      addCompanies: false,
      selectedProducts: null,
      items: [
        {label: '企业会员管理'},
        {label: '企业列表', to: '/enterpriseList'}
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      filters: {},
      submitted: false,
      sendModal: false,
      page: {
        total: 0,
        size: 10,
        current: 1,
      }
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$http.get("/companies/getCompaniesList", {
        params: {
          current: this.page.current,
          size: this.page.size,
          name: this.name,
          time: this.time,
        }
      }).then((res) => {
        this.products = res.data.records
        console.log(res.data.records)
        this.page.current = res.data.current
        this.page.total = res.data.total
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getDataList()
    },


    addCompaniesEvent() {
      this.addCompanies = true
      this.$nextTick(() => {
        this.$refs.addCompanies.init()
      })
    },
    setMember(data) {
      this.$http.post('/companies/setMember/' + data.id).then(() => {
        this.$toast.add({
          severity: 'success', summary: '设置成功！'
        })
        this.getDataList();
      })
    },
    goto(data) {
      //& data.creditCode != null & data.addTime != null & data.industry != null
      if (data.name != null & data.phone != null) {
        this.$router.push('/enterpriseDetailsEdit?id=' + data.id)
      } else {
        this.$toast.add({severity: 'warn', summary: '警告', detail: '该企业未完成信息认证，请立即联系该企业完成认证', life: 3000});
      }
    },
    confirmDeleteProducts() {
      this.sendModal = true;
    },
    closeModal() {
      this.sendModal = false;
    },
    confirmDeleteProduct(product) {
      this.product = product;

      console.log(product)
      this.deleteProductDialog = true;
    },
    saveProduct() {
      this.submitted = true;

      if (this.product.name.trim()) {
        if (this.product.id) {
          this.$set(this.products, this.findIndexById(this.product.id), this.product);
          this.$toast.add({severity: 'success', summary: '成功', detail: '更新成功', life: 3000});
        } else {
          this.product.id = this.createId();
          this.product.image = 'product-placeholder.svg';
          this.products.push(this.product);
          this.$toast.add({severity: 'success', summary: '成功', detail: '添加成功', life: 3000});
        }

        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.product = {...product};
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    deleteProduct(id) {

      this.$http.post('/companies/delCompaies?id=' + id).then(() => {
        this.deleteProductDialog = false
        this.getDataList()
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
      })
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(val => !this.selectedProducts.includes(val));
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({severity: 'success', summary: '成功', detail: 'Products Deleted', life: 3000});
    }
  }
}
</script>

<style scoped>

</style>
